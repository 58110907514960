
@font-face {
    font-family: "HelveticaNowDisplayW01-Rg";
    src: url("./Pages/Scripts/Assets/fonts/Helvetica Now Display W02 Rg.ttf");
}

@font-face {
    font-family: "TimesNR-Rg";
    src: url("./Pages/Scripts/Assets/fonts/Times NR MT W03 Regular.ttf");
}

@font-face {
    font-family: "HelveticaLTW01-Black";
    src: url("./Pages/Scripts/Assets/fonts/Helvetica Now Display W02 Black.ttf");
}

*
{
    text-decoration: none;
    list-style-type:none;
    margin:0;
    padding: 0;
}

:root
{
    --vert_heading_space:200px;
    --large_vert_content_space:calc(var(--large_max_content_width) - var(--vert_heading_space));
    --company_accent_primary: #91233B;
    --navbar_height: 30px;
    --navbar_logo_size: 70%;
    --navbar_color: #FFF;
    --navbar_fontsize: 16px;
    --max_content_width: 920px;
    --large_max_content_width: 1028px;
}

.flex-wrapper {
    position: relative;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
}

body {
    margin: 0;
    font-family: HelveticaNowDisplayW01-Rg, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* NAVIGATION */


@media screen and (min-width: 921px) {
    /*  NAVIGATION  */
    nav {
        position: fixed;
        width: 100vw;
        z-index: 99999;
    }

    .nav-container {

        flex: 1;
        object-fit: cover;
        background-color: var(--navbar_color);
        min-width: 921px;
        display: flex;
        gap: 10px;
        padding: 10px;
        justify-content: center;
        overflow: hidden;
        box-shadow: none;
        border-bottom: 3px solid var(--company_accent_primary);

    }


    /*  NAV ITEM PROPERTIES  */
    .nav-inner {
        height: var(--navbar_height);
        width: calc(100% - 90px);
        min-width: 320px;
        font-size: 0;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .nav-left {

        float: left;
        font-size: var(--navbar_fontsize);
        display: inline-flex;

    }

    .nav-right {
        margin-left: auto;
        font-size: var(--navbar_fontsize);
        display: inline-flex;
    }

    .nav-left
    {
        padding: 0 20px 0 0;

    }
    /*.navbar-items*/
    /*{*/
    /*    padding: 0 20px 0 20px;*/
    /*}*/

    /*  HIDE NAVBAR  */

    button
    {
        width: 0;
        border: none;
        background:none;
        padding: 0;
    }

    #menu-content
    {
        visibility: hidden;
    }

    .menu-content .links-hidden
    {
        font-size: 0;
    }

    .dropdown-menu
    {
        visibility: hidden;
        width: 0;
    }

    /*  ASSETS  */

    .nav-left a
    {
        color: black;
        text-decoration: underline 0.15em rgba(255, 255, 255, 0);
        text-underline-offset: 10px;
        transition: text-decoration-color 600ms;
    }

    .nav-left a:hover
    {
        color: var(--company_accent_primary);
        /*text-underline-offset: 4px;*/
        /*text-decoration-color: rgba(145, 35, 59, 1);*/
    }

    .nav-search-asset
    {
        width: var(--navbar_fontsize);
    }

    .logo-img-size
    {
        width: calc(var(--navbar_fontsize)*1.67);
    }

    .logo-image
    {
        padding-right: 20px;
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: inline-flex;
        /*margin-top: -6;*/
        border-radius: 50%;
        overflow: visible;
    }


    .nav-links
    {
        position: relative;

    }
    /*line after logo text*/
    .nav-first::before
    {
        padding: 0 20px 0 0;
        /*vertical-align: middle;*/
        color: black;
        /*padding:0 20px 0 20px;*/
        /*display:inline-block;*/
        /*line-height: 100px;*/
        content: '';
        border-left: 1px solid grey;
        /*height: var(--navbar_height);*/
        /*position: relative;*/
        /*right: 0;*/
        margin-left: -3px;
        /*top: 0;*/
    }
}

@media screen and (max-width: 920px)
{
    /*  PURE NAV (SMALL)  */
    nav
    {
        position: fixed;
        height: 100%;
        width: 100vw;
        z-index: 99999;
    }
    .nav-left
    {
        padding: 10px;
    }

    .nav-container
    {


        flex: 1;
        object-fit: cover;
        background-color: var(--navbar_color);
        display: flex;
        flex-direction: column;

        justify-content: center;
    }

    .nav-inner
    {
        height: 50px;
        width: 100%;
        min-width: 320px;
        font-size: 0;
        overflow: hidden;
        display: inline-flex;
        align-items:center;
        justify-content: center;

    }

    .nav-left
    {
        display: inline-flex;
        align-items: flex-start;
        padding: 10px;

    }

    .nav-right
    {
        padding: 20px;
        margin-left: auto;
        align-items: flex-end;
        font-size: var(--navbar_fontsize);
    }

    /*  DROP BAR -- SMALL NAV MENU  */


    button
    {
        border: none;
        background:none;
    }


    .small-menu-button
    {
        padding: 20px;
        object-fit: cover;
        overflow: visible;
    }

    #small-menu-button:hover
    {
        background-color: grey;
        overflow: hidden;

    }

    .small-menu-button-size
    {
        width: 20px;
    }

    .dropdown-menu {
        position: relative;
        overflow: visible;
    }
    #black-tint
    {
        content: '\A';
        width: 100%; height:100%;
        top:0; left:0;
        position: absolute;
        visibility: hidden;
        overflow: visible;
        opacity: 50%;
        background-color: black;
        z-index: 99;
    }
    #menu-content{
        visibility: hidden;
        position: fixed;
        right: auto;
        top: 50px;
        background-color: #FFFFFF;
        width: 30%;
        min-width: 200px;
        height: 100vh;
        z-index: 99999;
    }

    .links,.links-hidden{
        display: inline-block;
        color: rgb(0, 0, 0);
        padding: 12px 16px;
        text-decoration: none;
        font-size: 18px;
        font-weight: bold;
    }

    .links-hidden{
        display: block;
    }

    .links-hidden:active,.links:active {
        background-color: grey;
    }

    .dropdown-menu:active .menu-btn {
        background-color: #3e8e41;
    }

    /*  Assets  */

    .nav-search-asset
    {
        width: var(--navbar_fontsize);
    }


    .logo-img-size
    {
        width: calc(var(--navbar_fontsize)*1.5);
        padding: 0 0 0 20px;


    }

    .logo-image
    {
        object-fit: cover;
        height: 100%;
        width: 100%;
        overflow: visible;
    }

    .logo-text
    {
        font-size: var(--navbar_fontsize);
        color: black;
        position:relative
    }

}

/* Footer */

.footer
{
    padding: 10px;
    font-size: var(--navbar_fontsize);

}

.foot-right
{
    float: right;
}
.indent
{
    text-indent: 30px;
}

footer
{
    /*position:fixed;*/
    bottom:0;
    left:0;
    /*position: relative;*/
    margin-top:auto;
    display: block;
    width: 100%;
    background-color: var(--company_accent_primary);
    color: white;
    position: absolute;
    /*bottom: 0;*/

    /*padding: 10px;*/
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 100;

}

/*  Content / Pages  */
/*  Home  */

p a
{
    color: var(--company_accent_primary);
}
body
{
    color: black;
}

h1
{
    font-family: HelveticaLTW01-Black, sans-serif;
}


.bg
{
    z-index: -3;
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    background-image: url("./Pages/Scripts/Assets/cti_background.png") !important;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: black;
}

.content-wrapper
{
    /*background-color: black;*/
    display: table;
    min-height: 100vh;
    /*padding-top: 70px;*/
    /*display: block;*/
    /*padding-top: calc(var(--navbar_height));*/
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
}

#start-page
{
    margin-top: 40px;
}

#testimonial-container p
{
    margin-bottom:30px ;
}

#testimonial
{
    /*display: inline-grid;*/
    /*position: center;*/
    /*vertical-align: top;*/
}

#name
{
    vertical-align: bottom;
}

#testimonial-container
{
    /*width: 200px;*/
    border-radius: 25px;
    height: 100%;
    /*width: 300px;*/
    padding: 15px;
    display: grid;
    background-color: rgba(255, 255, 255, 0.4);
    /*align-content: space-evenly;*/
    align-content: end;
}

#testimonial-container span
{
    opacity: 100%;
    padding-top:10px;
    display: inline-block;
    width: 220px;
    font-size: 16px;

}

#testimonials
{
    position: relative;
    width:100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 30px 0 30px 0;
}


.landing
{
    min-height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 100vh;
    padding: 30px 50px 50px 50px;
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(255,255,255,.90) 0%, rgba(0,0,0,0) 85%);
    background-color: rgba(255, 255, 255, 0.2);
}

#company img
{
    height: 40px;
}

#catch-align
{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    vertical-align: middle;
    display: inline-flex;
    -webkit-flex-direction: column;
    -webkit-box-pack: center;
    -webkit-flex-pack: center;
    -webkit-justify-content: center;
    -webkit-flex-align: center;
    -webkit-align-items: center;
}

.wreath
{
    height: 100px;
}

.text
{
    font-family: TimesNR-Rg, serif;
    padding-right: 10px;
    padding-left: 10px;
}

.inner-catch
{
    margin: 0;
    border: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.landing-catch
{
    vertical-align: middle;
    position: relative;
    display: inline-block;
    padding: 50px;
    width: 600px;
}


.landing-head
{
    text-align: center;
}

h1 {
    font-size: 50px;
}

.information p
{
    font-size: 17px;
}

p {
    font-size: 20px;
}


html {
    background-color: white;
}



h1
{
    text-transform: capitalize;
    /*color: var(--company_accent_primary);*/
    /*display: block;*/
}

.headings, .information
{
    /*display: inline-grid;*/
    /*grid: 100px / auto auto auto;*/

}

.first-content
{
    padding-top: var(--navbar_height);
}

.information
{
    display: inline-block;
    /* remove padding done in heading from overall width to space out the two divs*/
    width: calc(var(--large_vert_content_space) - 30px);

}

h2
{
    font-family: HelveticaNowDisplayW01-Rg, serif;
    font-size: 15px;
    text-transform: uppercase;
}

.headings
{

    padding-right: 30px;
    float: left;
    vertical-align: top;
    display: inline-block;
    width: var(--vert_heading_space)
}

.service
{
    padding:20px 0 20px 0;
    display: block;
}


.content
{
    height: 100%;
    width: var(--large_max_content_width);
    padding:  calc(var(--navbar_height) + 40px) 0 calc(var(--navbar_height) + 40px) 0 ;
}